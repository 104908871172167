import {
	reactive, UnwrapNestedRefs, onMounted, onUpdated,
	onBeforeMount, onUnmounted, onBeforeUpdate,
	onBeforeUnmount, onActivated, onDeactivated
} from "vue";

import { SetupOptions } from "./SetupOptions";
import { isOnMounted } from "./OnMounted";
import { isOnUpdated } from "./OnUpdated";
import { isOnUnmounted } from "./OnUnmounted";
import { isOnBeforeUpdate } from "./OnBeforeUpdate";
import { isOnBeforeMounted } from "./OnBeforeMount";
import { isBeforeUnmount } from "./OnBeforeUnmount";
import { isOnActivated } from "./OnActivated";
import { isOnDeactivated } from "./OnDeactivated";

declare type Method = () => void;
declare type Anonymous = { [key: string]: unknown | undefined };

const setupComponent = <TCodeBehind extends object>(codeBehind: TCodeBehind, options?: SetupOptions): UnwrapNestedRefs<TCodeBehind> => {

	if (isOnBeforeMounted(codeBehind)) {
		onBeforeMount(forceCast(codeBehind.beforeMount.bind(codeBehind)));
	}

	if (isOnMounted(codeBehind)) {
		onMounted(forceCast(codeBehind.mounted.bind(codeBehind)));
	}

	if (isOnBeforeUpdate(codeBehind)) {
		onBeforeUpdate(forceCast(codeBehind.beforeUpdate.bind(codeBehind)));
	}

	if (isOnUpdated(codeBehind)) {
		onUpdated(forceCast(codeBehind.updated.bind(codeBehind)));
	}

	if (isBeforeUnmount(codeBehind)) {
		onBeforeUnmount(forceCast(codeBehind.beforeUnmount.bind(codeBehind)));
	}

	if (isOnUnmounted(codeBehind)) {
		onUnmounted(forceCast(codeBehind.unmounted.bind(codeBehind)));
	}

	if (isOnActivated(codeBehind)) {
		onActivated(forceCast(codeBehind.activated.bind(codeBehind)));
	}

	if (isOnDeactivated(codeBehind)) {
		onDeactivated(forceCast(codeBehind.deactivated.bind(codeBehind)));
	}

	const reactiveProxy = reactive(codeBehind);
	bindProxyMethodsToCodeBehind<TCodeBehind>(codeBehind, reactiveProxy);

	return Object.assign(reactiveProxy, options?.extras ?? {});
}

const bindProxyMethodsToCodeBehind = <TCodeBehind extends object>(codeBehind: TCodeBehind,
	reactiveProxy: UnwrapNestedRefs<TCodeBehind>): void => {

	for (const member of getMemberNames(codeBehind)) {
		if (typeof forceCast<Anonymous>(codeBehind)[member] === "function") {
			forceCast<Anonymous>(reactiveProxy)[member] =
				forceCast<Method>(forceCast<Anonymous>(codeBehind)[member]).bind(codeBehind);
		}
	}
}

const getMemberNames = (o: object): readonly string[] => {
	const memberNames = new Set<string>();

	for (const member in o) {
		memberNames.add(member);
	}

	let prototype: object = forceCast<Anonymous>(o)["__proto__"] as object;
	do {
		for (const member of Object.getOwnPropertyNames(prototype)) {
			if (member !== "constructor") {
				memberNames.add(member);
			}
		}

		prototype = forceCast<Anonymous>(prototype)["__proto__"] as object;
	} while (prototype && prototype.constructor.name !== "Object");

	return [ ...memberNames.values() ];
}

const forceCast = <T>(value: unknown): T => {
	return value as T;
}

export {
	setupComponent
};
