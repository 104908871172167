interface OnBeforeMount {
	beforeMount(): void | Promise<void>;
}

const isOnBeforeMounted = (o: unknown): o is OnBeforeMount => {
	return typeof (o as OnBeforeMount).beforeMount === "function";
}

export {
	OnBeforeMount,
	isOnBeforeMounted
}
