interface OnActivated {
	activated(): void | Promise<void>;
}

const isOnActivated = (o: unknown): o is OnActivated => {
	return typeof (o as OnActivated).activated === "function";
}

export {
	OnActivated,
	isOnActivated
}
