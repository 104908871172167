interface OnMounted {
	mounted(): Promise<void> | void;
}

const isOnMounted = (o: unknown): o is OnMounted => {
	return typeof (o as OnMounted).mounted === "function";
}

export {
	OnMounted,
	isOnMounted
}
