interface OnBeforeUpdate {
	beforeUpdate(): void | Promise<void>;
}

const isOnBeforeUpdate = (o: unknown): o is OnBeforeUpdate => {
	return typeof (o as OnBeforeUpdate).beforeUpdate === "function";
}

export {
	OnBeforeUpdate,
	isOnBeforeUpdate
}
