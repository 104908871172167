interface OnUpdated {
	updated(): Promise<void> | void;
}

const isOnUpdated = (o: unknown): o is OnUpdated => {
	return typeof (o as OnUpdated).updated === "function";
}

export {
	OnUpdated,
	isOnUpdated
}
