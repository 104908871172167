interface OnUnmounted {
	unmounted(): Promise<void> | void;
}

const isOnUnmounted = (o: unknown): o is OnUnmounted => {
	return typeof (o as OnUnmounted).unmounted === "function";
}

export {
	OnUnmounted,
	isOnUnmounted
}
