interface OnBeforeUnmount {
	beforeUnmount(): void | Promise<void>;
}

const isBeforeUnmount = (o: unknown): o is OnBeforeUnmount => {
	return typeof (o as OnBeforeUnmount).beforeUnmount === "function";
}

export {
	OnBeforeUnmount,
	isBeforeUnmount
}
