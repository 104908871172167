interface OnDeactivated {
	deactivated(): void | Promise<void>;
}

const isOnDeactivated = (o: unknown): o is OnDeactivated => {
	return typeof (o as OnDeactivated).deactivated === "function";
}

export {
	OnDeactivated,
	isOnDeactivated
}
